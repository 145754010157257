import React, { useState } from 'react';
import { Form, Input, Button, Upload, Typography, Checkbox, notification, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const key = "c292c3eadf1dc34096ca4c91095cfd5d313879be";

const { Text } = Typography;

function App() {
  const [url, setUrl] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, description) => {
    api[type]({
      title,
      description,
    });
  };

  const uploadImageToGoogleCloud = async () => {
    let formData = new FormData();
    formData.append('file', file.data);
    const response = await fetch(
      `${
        process.env.REACT_APP_API_URL || 'https://api.density.one'
      }/api/upload-file-to-cloud-storage`,
      {
        method: 'POST',
        body: formData,
      }
    );
    const responseWithBody = await response.json();
    if (response) {
      setUrl(responseWithBody.publicUrl);
    }
    return responseWithBody.publicUrl;
  };
  const handleFileChange = ({ file, fileList }) => {
    console.log(file, fileList);
    if (file.status === 'done') {
      setImageFile(file.originFileObj);
    }
    if (fileList.length) {
      try {
        const img = {
          preview: URL.createObjectURL(file),
          data: file,
        };
        setFile(img);
      } catch (error) {
        console.error('Error during file preview:', error);
      }
    }
  };
  const [form] = Form.useForm();
  const [imageFile, setImageFile] = useState(null);
  const [generatedUrl, setGeneratedUrl] = useState(null);
  const [longUrl, setLongUrl] = useState(null);

  const bitlyToken = 'bf1f32ba8d7daab20632d296c619dbede1beacaf';  // Replace YOUR_BITLY_TOKEN with your actual Bitly token

  const shortenUrl = async (url, title) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${bitlyToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        long_url: url,
        domain: 'dj.density.one',
        title: title,
      })
    };
  
    try {
      const response = await fetch('https://api-ssl.bitly.com/v4/shorten', requestOptions);
      const data = await response.json();
      console.log(data, response);
      if (response.ok) {
        return data.link;  // The customized shortened URL
      } else {
        throw new Error(`Bitly Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error customizing and shortening URL:', error);
      return url; // Return the original URL in case of an error
    }
  };

  const updateShortLink = async (sLink, backHalf) => {
    try {
      const response = await fetch('https://api-ssl.bitly.com/v4/custom_bitlinks', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${bitlyToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "custom_bitlink": `dj.density.one/${backHalf}`, "bitlink_id": sLink.replace('https://', '') })
    });
      const data = await response.json();
      console.log(data, response);
      if (response.ok) {
        return data.custom_bitlink;  // The customized shortened URL
      } else {
        throw new Error(`Bitly Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error customizing and shortening URL:', error);
      return sLink; // Return the original URL in case of an error
    }
    
  }

  const onFinish = async (values) => {
    console.log(values)
    if (values.proInvite) {
      const djUrl = 'https://density.one/onboarding/0?proInvite=true';
      const shortUrl = await shortenUrl(djUrl, values.reffererName, values.social, values.campaign, values.backHalf);
      setGeneratedUrl(shortUrl);
      setLongUrl(djUrl);
      return;
    }
    if (!values.referrerId) {
      openNotificationWithIcon('error', 'Error', 'Please enter a referrer ID');
      return;
    }
    if (!values.referrerName) {
      openNotificationWithIcon('error', 'Error', 'Please enter a referrer name');
      return;
    }
    if (imageFile && imageFile.size / 1024 / 1024 > 2) {
      openNotificationWithIcon('error', 'Error', 'Image must be smaller than 2MB');
      return;
    }
    if (imageFile) {
      try {
        setLoading(true);
        const imageUrl = await uploadImageToGoogleCloud(imageFile);
        let djUrl = `https://density.one/onboarding/0/${values.referrerId}?referrer=${encodeURIComponent(
          values.referrerName
        )}&imgUrl=${encodeURIComponent(imageUrl)}`
        if (values.landing) {
          djUrl += '&landing=true';
        }
        if (values.utm_source) {
          djUrl += `&utm_source=${values.utm_source}`;
        }
        if (values.utm_medium) {
          djUrl += `&utm_medium=${values.utm_medium}`;
        }
        const backHalf = values.referrerName.toLowerCase().replace(' ', '');
        djUrl += `&utm_campaign=${backHalf}`;
        const shortUrl = await shortenUrl(djUrl, values.referrerName);
        const customShortUrl = await updateShortLink(shortUrl, backHalf);
        setGeneratedUrl(customShortUrl);
        setLongUrl(djUrl);
        setLoading(false);
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    } else {
      let djUrl = `https://density.one/onboarding/0/${values.referrerId}?referrer=${encodeURIComponent(
        values.referrerName
      )}`
      if (values.landing) {
        djUrl += '&landing=true';
      }
      if (values.utm_source) {
        djUrl += `&utm_source=${values.utm_source}`;
      }
      if (values.utm_medium) {
        djUrl += `&utm_medium=${values.utm_medium}`;
      }
      const backHalf = values.referrerName.toLowerCase().replace(' ', '');
        djUrl += `&utm_campaign=${backHalf}`;
      const shortUrl = await shortenUrl(djUrl)
      const customShortUrl = await updateShortLink(shortUrl, backHalf);
      setGeneratedUrl(
        customShortUrl
      );
      setLongUrl(djUrl);
    }
  };

  const beforeUpload = (file) => {
    setImageFile(file);
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return false;
  };

  return (
    <div
      className="App"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
    >
      {contextHolder}
      <div style={{ width: '600px', paddingTop: '150px' }}>
        <Form initialValues={{ utm_source: 'DJ', utm_medium: 'social' }} form={form} onFinish={onFinish} layout="vertical">
          <Form.Item name="referrerId" label="DJ Referrer ID">
            <Input />
          </Form.Item>
          <Form.Item name="referrerName" label="DJ Name">
            <Input />
          </Form.Item>
          <Form.Item name="utm_source" label="utm_source">
            <Input />
          </Form.Item>
          <Form.Item name="utm_medium" label="utm_medium">
            <Input />
          </Form.Item>
          <Form.Item label="Upload Image">
            <Upload
              onChange={handleFileChange}
              beforeUpload={beforeUpload}
              listType="picture"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="proInvite" label="Pro invite" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item name="landing" label="Show landing page" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {loading ? 'Uploading, please wait...' : 'Generate URL'}
            </Button>
          </Form.Item>
        </Form>
        <div>
          {generatedUrl && <Text type="secondary"><b>bitly:</b> {generatedUrl} <br/><br/></Text>}
        </div>
        <div>{longUrl && <Text type="secondary"><b>Long url:</b> {longUrl}</Text>}</div>
      </div>
    </div>
  );
}

export default App;
